import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../components/Layout"
import SignContainer from "../containers/SignContainer"
import PaymentsContainer from "../containers/PaymentsContainer"
import axios from "axios"
import { setSignUpPopup } from "../store/modules/popup"
import { dataCheckFn, localStorgeGet, popupFn } from "../utils/util"

//소셜로그인 회원가입 네이버 콜백 페이지
const authLogin = props => {
  const { postSignIn, postSignupDatas, setAuthEmailSeq } = props

  const signUpPopup = useSelector(state => state.popup.signUpPopup)
  const url = typeof window !== "undefined" ? props.location.hash : ""

  const dispatch = useDispatch()

  const [sign, setSign] = useState(false)

  //인증정보 get
  const initializeNaverLogin = async () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.NAVER_CLIENT_ID,
      callbackUrl: `${process.env.CALLBACK_URL}/authLogin`,
      isPopup: false,
      loginButton: { color: "white", type: 1, height: "47" },
    })
    naverLogin.getLoginStatus(async status => {
      if (status) {
        let email = naverLogin.user.getEmail()
        let id = naverLogin.user.getId()
        if (props.location.hash) {
          let orderInfo = props.location.hash
          let reqTemp = orderInfo.split("&")
          let navToken = ""

          for (let i of reqTemp) {
            if (i.indexOf("access_token=") != -1) navToken = i.split("access_token=")[1]
          }

          let seq = ""
          let token = ""
          let deviceNumber = ""
          let test = navigator.userAgent
          let arr = test.split(" ")
          for (let i of arr) {
            if (i.indexOf("token_") != -1) {
              token = i.split("oken_")[1]
            } else if (i.indexOf("deviceNumber") != -1) {
              deviceNumber = i.split("Number")[1]
            }
          }
          let studySeq = localStorgeGet("inviteStudy")
          if (studySeq) {
            let tempArr = studySeq.split("&")
            for (let i of tempArr) {
              if (i.indexOf("studySeq") != -1) {
                seq = i.split("=")[1]
              }
            }
          }
          if (!navToken) return false
          //로그인
          if (localStorgeGet("naverType") == "login") {
            let temp = {
              signType: "NAVER",
              signId: id,
              signToken: navToken,
              email: email,
              password: "",
              fcmToken: token,
              deviceNumber: deviceNumber,
              studySeq: seq,
            }

            let result = await postSignIn(temp)
            if (result) {
              localStorage.removeItem("naverType")
              if (studySeq) {
                localStorage.removeItem("inviteStudy")
                navigate("/exploring" + studySeq)
              } else {
                navigate("/exploring")
              }
            } else {
              navigate("/exploring")
              localStorage.removeItem("naverType")
            }
          } else if (localStorgeGet("naverType") == "join") {
            //회원가입
            let temp = {
              name: "",
              nickname: "",
              email: email,
              password: "",
              signType: "NAVER",
              signId: id,
              signToken: token,
              callback: "",
              studySeq: seq,
            }
            let result = await postSignupDatas(temp)
            if (result) {
              setAuthEmailSeq({ authEmailSeq: "", customerAppSignDataSeq: result.customerAppSignDataSeq })
              dispatch(setSignUpPopup(true))
              setSign(true)
              localStorage.removeItem("naverType")
            } else {
              localStorage.removeItem("naverType")
              navigate("/exploring")
            }
          }
        }
      } else {
      }
    })
    naverLogin.init()
  }

  const getNaverToken = () => {
    if (url == "" || url == undefined || url.includes("error")) {
      popupFn("로그인 실패")
      navigate("/exploring")
      return false
    }
  }
  useEffect(async () => {
    getNaverToken()
    initializeNaverLogin()
  }, [])

  //회원가입완료시 페이지 리다이렉트
  useEffect(() => {
    if (!signUpPopup && sign) {
      let studySeq = localStorgeGet("inviteStudy")
      if (studySeq) {
        localStorage.removeItem("inviteStudy")
        navigate("/exploring" + studySeq)
      } else {
        navigate("/exploring")
      }
    }
  }, [signUpPopup])
  return (
    <Layout>
      <div></div>
    </Layout>
  )
}

export default SignContainer(authLogin)
