import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  setPaymentsOrderId,
  postPaymentsOrderAction,
  postPaymentsVerificationAction,
  postPaymentsSuccessAction,
  postPaymentsFailAction,
} from "../store/modules/payments"

// redux state > component mapping
const mapStateToProps = state => ({
  orderId: state.payments.orderId,
})

// redux action > component mapping
const mapDispatchToProps = dispatch => ({
  // 결제 ID 생성
  postPaymentsOrder: param => dispatch(postPaymentsOrderAction(param)),
  setPaymentsOrderId: param => dispatch(setPaymentsOrderId(param)),
  postPaymentsVerification: param => dispatch(postPaymentsVerificationAction(param)),
  postPaymentsSuccess: param => dispatch(postPaymentsSuccessAction(param)),
  postPaymentsFail: param => dispatch(postPaymentsFailAction(param)),
})

const WithHoc = ChildComponent => props => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
